

import React, { useState, /*useEffect*/ } from 'react';
import './App.css';
import axios from 'axios';
// import logo from './assets/images/RunCastLogo_transparent.png';
import validator from 'validator';
import logo from './assets/images/runcast logo new.JPG'

function App() {
  const [eMail, setEMail] = useState('');
  const [zipCode, setZipCode] = useState('');
  // const [timeZone, setTimeZone] = useState('');
  // const [timeZones, setTimeZones] = useState([]);
  const [runTime, setRunTime] = useState('');
  // const [notificationTime, setNotificationTime] = useState('');
  // const [notificationTimeBefore, setNotificationTimeBefore] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // New state for success message

  // useEffect(() => {
  //   const loadTimeZones = async () => {
  //     try {
  //       const response = await fetch('/timezones.json');
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const timeZones = await response.json();
  //       console.log('Loaded time zones:', timeZones);
  //       setTimeZones(timeZones);
  //     } catch (error) {
  //       console.error('Error loading time zones:', error);
  //       setError('Failed to load time zones');
  //     }
  //   };
  //   loadTimeZones();
  // }, []);

  const handleEMailChange = (e) => setEMail(e.target.value);
  const handleZipCodeChange = (e) => setZipCode(e.target.value);
  // const handleTimeZoneChange = (e) => setTimeZone(e.target.value);
  const handleRunTimeChange = (e) => setRunTime(e.target.value);
  // const handleNotificationTimeChange = (e) => setNotificationTime(e.target.value);
  // const handleNotificationTimeBeforeChange = (e) => setNotificationTimeBefore(e.target.value);

  // Function to convert 12-hour format to 24-hour format
  const convertTo24HourFormat = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const validateZipCode = (zip) => {
    const zipRegEx = /^\d{5}(-\d{4})?$/;
    return zipRegEx.test(zip);
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default behavior for form
    if(!validator.isEmail(eMail)){
      setError('Invalid Email Format');
      return;
    }

    if(!validateZipCode(zipCode)){
      setError('Invalid Zip Code, enter a 5 digit zip code');
      return;
    }

    

  

    // Convert runTime to 24-hour format before submission
    const runTime24 = convertTo24HourFormat(runTime);

    console.log('Form data before submission:', {
      eMail,
      zipCode,
      // timeZone,
      runTime: runTime24, // Log the converted time
      // notificationTime,
      // notificationTimeBefore
    });

    const requestData = {
      eMail: eMail,
      zipCode: zipCode,
      // timeZone: timeZone,
      runTime: runTime24, // Use the converted run time
      // notificationTime: notificationTime,
      // notificationTimeBefore: notificationTimeBefore
    };

    // Clear error and success messages
    setError('');
    setSuccess('');

    try {
      // const response = await axios.post("http://localhost:8080/submit", requestData, {
        const response = await axios.post("http://api.runcastapp.com/submit", requestData, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      console.log("Success:", response.data);

      if (response.data.status !== "Error") {
        setSuccess(`Successfully registered! Check your email at ${eMail} for updates.`);
        setEMail('');
        setZipCode('');
        setRunTime('');
        // setNotificationTime('');
        // setNotificationTimeBefore('');
        // setTimeZone('');
        console.log('State after reset:', { eMail, zipCode, /*timeZone,*/ runTime/*, notificationTime, notificationTimeBefore*/ });
      } else {
        setError(response.data.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);  // Set specific error message from backend
      } else {
        setError("Error occurred while trying to save to database");  // Set generic error message
      }
    }
  };

  const hours = [
    "12:00 AM", "1:00 AM", "2:00 AM", "3:00 AM", "4:00 AM", "5:00 AM", "6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM",
    "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM", "11:00 PM"
  ];

  return (
    <div className="App">
      <header className="App-header">
        
        {/*<p className="custom-paragraph">
          RunCast
        </p>*/}
        <div className='image-container'>
          <img src={logo} alt="RunCast Logo" />
        </div>
      </header>
 
      <main className="body-color">
        <div>
          <section className="body-color">
            <h2 className="font-size">Enter a few details to get notified!</h2>
            <form onSubmit={handleSubmit} className="zip-form">
              {error && <div className="error-message">{error}</div>}
              {success && <div className="success-message">{success}</div>} {/* Display success message */}
              <div className="form-group">
                <label htmlFor="eMail">Email For Notifications</label>
                <input
                  type="text"
                  id="eMail"
                  value={eMail}
                  onChange={handleEMailChange}
                  placeholder="example@yourservice.com"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="zipCode">Current ZIP Code</label>
                <input
                  className="form-control"
                  type="text"
                  id="zipCode"
                  value={zipCode}
                  onChange={handleZipCodeChange}
                  placeholder="Enter current ZIP Code"
                />
              </div>

              <div className="form-group">
                <label htmlFor="runTime">Average Time You Run</label>
                <select
                  className="form-control"
                  id="runTime"
                  value={runTime}
                  onChange={handleRunTimeChange}
                >
                  <option value="" disabled>Select time</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>{hour}</option>
                  ))}
                </select>
              </div>

              {/* <div className= "form-group">

              <label htmlFor='runTime'>
                Average Time You Run
              </label>
              <input
              className='form-control'
              type='time'
              id = 'runTime'
              value={runTime}
              onChange={handleRunTimeChange}
            />
              </div> */}

              {/*<div className= "form-group">

              <label htmlFor='notificationTime'>
              Notification: before run time
              </label>
              <input
              className='form-control'
              type='time'
              id = 'notificationTime'
              value={notificationTime}
              onChange={handleNotificationTimeChange}
            />
              </div>

              <div className= "form-group">

              <label htmlFor='notificationTimeBefore'>
                Notification: day / night before
              </label>
              <input
              className='form-control'
              type='time'
              id = 'notificationTimeBefore'
              value={notificationTimeBefore}
              onChange={handleNotificationTimeBeforeChange}
              />
              </div>*/}
              
              <button
                type="submit"
                className="btn btn-primary">Get Notified!
                </button>
                
            </form>

            
            <p className="bottom-link">For Awesome fun-ness and the most CRINGE you've ever seen <a className = "link" href="https://www.youtube.com/shorts/fMOWZryZdMQ" target="_blank" rel="noopener noreferrer">Click here!</a></p>
          </section>
        </div>
      </main>
    </div>
  );
}

export default App;



// import React, { useState, /*useEffect*/ } from 'react';
// import './App.css';
// import axios from 'axios';
// import logo from './assets/images/RunCastLogo_transparent.png';

// function App() {
//   const [eMail, setEMail] = useState('');
//   const [zipCode, setZipCode] = useState('');
//   // const [timeZone, setTimeZone] = useState('');
//   // const [timeZones, setTimeZones] = useState([]);
//   const [runTime, setRunTime] = useState('');
//   // const [notificationTime, setNotificationTime] = useState('');
//   // const [notificationTimeBefore, setNotificationTimeBefore] = useState('');
//   const [error, setError] = useState('');

//   // useEffect(() => {
//   //   const loadTimeZones = async () => {
//   //     try {
//   //       const response = await fetch('/timezones.json');
//   //       if (!response.ok) {
//   //         throw new Error('Network response was not ok');
//   //       }
//   //       const timeZones = await response.json();
//   //       console.log('Loaded time zones:', timeZones);
//   //       setTimeZones(timeZones);
//   //     } catch (error) {
//   //       console.error('Error loading time zones:', error);
//   //       setError('Failed to load time zones');
//   //     }
//   //   };
//   //   loadTimeZones();
//   // }, []);

//   const handleEMailChange = (e) => setEMail(e.target.value);
//   const handleZipCodeChange = (e) => setZipCode(e.target.value);
//   // const handleTimeZoneChange = (e) => setTimeZone(e.target.value);
//   const handleRunTimeChange = (e) => setRunTime(e.target.value);
//   // const handleNotificationTimeChange = (e) => setNotificationTime(e.target.value);
//   // const handleNotificationTimeBeforeChange = (e) => setNotificationTimeBefore(e.target.value);

//   // Function to convert 12-hour format to 24-hour format
//   const convertTo24HourFormat = (time12h) => {
//     const [time, modifier] = time12h.split(' ');
//     let [hours, minutes] = time.split(':');
//     if (hours === '12') {
//       hours = '00';
//     }
//     if (modifier === 'PM') {
//       hours = parseInt(hours, 10) + 12;
//     }
//     return `${hours.toString().padStart(2, '0')}:${minutes}`;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault(); // Prevent default behavior for form

//     // Convert runTime to 24-hour format before submission
//     const runTime24 = convertTo24HourFormat(runTime);

//     console.log('Form data before submission:', {
//       eMail,
//       zipCode,
//       // timeZone,
//       runTime: runTime24, // Log the converted time
//       // notificationTime,
//       // notificationTimeBefore
//     });

//     const requestData = {
//       eMail: eMail,
//       zipCode: zipCode,
//       // timeZone: timeZone,
//       runTime: runTime24, // Use the converted run time
//       // notificationTime: notificationTime,
//       // notificationTimeBefore: notificationTimeBefore
//     };

//     // Clear error
//     setError('');

//     try {
//       // const response = await axios.post("https://ec2-18-227-190-18.us-east-2.compute.amazonaws.com/submit", requestData, {
//       // const response = await axios.post("http://localhost:8080/submit", requestData, {
//          const response = await axios.post("https://dfbaykgaxbcrp.cloudfront.net", requestData, {
        
//         headers: {
//           "Content-Type": "application/json"
//         }
//       });
//       console.log("Success:", response.data);

//       if (response.data.status !== "Error") {
//         setError('');
//         setEMail('');
//         setZipCode('');
//         setRunTime('');
//         // setNotificationTime('');
//         // setNotificationTimeBefore('');
//         // setTimeZone('');
//         console.log('State after reset:', { eMail, zipCode, /*timeZone,*/ runTime/*, notificationTime, notificationTimeBefore*/ });
//       } else {
//         setError(response.data.msg);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       if (error.response && error.response.data && error.response.data.msg) {
//         setError(error.response.data.msg);  // Set specific error message from backend
//       } else {
//         setError("Error occurred while trying to save to database");  // Set generic error message
//       }
//     }
//   };

//   const hours = [
//     "12:00 AM", "1:00 AM", "2:00 AM", "3:00 AM", "4:00 AM", "5:00 AM", "6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM",
//     "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM", "11:00 PM"
//   ];

//   return (
//     <div className="App">
//       <header className="App-header">
        
//         <p className="custom-paragraph">
//           RunCast
//         </p>
//         <div className='image-container'>
//           <img src={logo} alt="RunCast Logo" />
//         </div>
//       </header>
 
//       <main className="body-color">
//         <div>
//           <section className="body-color">
//             <h2 className="font-size">Enter a few details to get notified!</h2>
//             <form onSubmit={handleSubmit} className="zip-form">
//               {error && <div className="error-message">{error}</div>}
//               <div className="form-group">
//                 <label htmlFor="eMail">Email For Notifications</label>
//                 <input
//                   type="text"
//                   id="eMail"
//                   value={eMail}
//                   onChange={handleEMailChange}
//                   placeholder="example@yourservice.com"
//                   className="form-control"
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="zipCode">Current ZIP Code</label>
//                 <input
//                   className="form-control"
//                   type="text"
//                   id="zipCode"
//                   value={zipCode}
//                   onChange={handleZipCodeChange}
//                   placeholder="Enter current ZIP Code"
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="runTime">Average Time You Run</label>
//                 <select
//                   className="form-control"
//                   id="runTime"
//                   value={runTime}
//                   onChange={handleRunTimeChange}
//                 >
//                   <option value="" disabled selected>Select time</option>
//                   {hours.map((hour) => (
//                     <option key={hour} value={hour}>{hour}</option>
//                   ))}
//                 </select>
//               </div>

//               {/* <div className= "form-group">

//               <label htmlFor='runTime'>
//                 Average Time You Run
//               </label>
//               <input
//               className='form-control'
//               type='time'
//               id = 'runTime'
//               value={runTime}
//               onChange={handleRunTimeChange}
//             />
//               </div> */}

//               {/*<div className= "form-group">

//               <label htmlFor='notificationTime'>
//               Notification: before run time
//               </label>
//               <input
//               className='form-control'
//               type='time'
//               id = 'notificationTime'
//               value={notificationTime}
//               onChange={handleNotificationTimeChange}
//             />
//               </div>

//               <div className= "form-group">

//               <label htmlFor='notificationTimeBefore'>
//                 Notification: day / night before
//               </label>
//               <input
//               className='form-control'
//               type='time'
//               id = 'notificationTimeBefore'
//               value={notificationTimeBefore}
//               onChange={handleNotificationTimeBeforeChange}
//               />
//               </div>*/}
//               <button
//                 type="submit"
//                 className="btn btn-primary">Get Notified!</button>
//             </form>
//             <p className="bottom-link">For Awesome fun-ness and the most CRINGE you've ever seen <a className = "link" href="https://www.youtube.com/shorts/fMOWZryZdMQ" target="_blank" rel="noopener noreferrer">Click here!</a></p>
//           </section>
//         </div>
//       </main>
//     </div>
//   );
// }

// export default App;
